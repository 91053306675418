<template>
  <div>
    <div class="col-xl-12 header_mt">
      <div
        class="C000052_root__a9yOi"
        style="overflow-y: auto; margin-top: 100px"
      >
        <!-- <div
          class="C000052_vipArea__IficX justify-content-end mt-0alignitensalign-items-center"
        >
          <div @click="this.$router.push('/VIP_intro')" class="c-pointer">
            <span class="C000052_vipLevelName__H8TRa me-1">{{
              $t("home_text11")
            }}</span
            ><svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="question-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
              ></path>
              <path
                d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"
              ></path>
            </svg>
          </div>
        </div> -->

        <div class="C000052_userInfo__vhVx0">
          <div class="d-flex align-items-center">
            <span>
              <div
                class="C000052_userAvatar__kEXTF position-relative"
                @click="flag_head = true"
              >
                <span
                  class="ant-avatar ant-avatar-circle ant-avatar-image ant-avatar-icon"
                  style="
                    width: 70px;
                    height: 70px;
                    line-height: 70px;
                    font-size: 35px;
                  "
                  ><img
                    :src="
                      require('@/assets/img/big_head/' +
                        big_head_img_set +
                        '.png')
                    " /></span
                ><span class="C000052_edit__YiCar"
                  ><span
                    role="img"
                    aria-label="edit"
                    class="anticon anticon-edit"
                    ><svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="edit"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9z"
                      ></path></svg></span
                ></span>
              </div>
            </span>
            <div>
              <div
                class="C000052_vipArea__IficX ms-0 flex-column justify-content-start align-items-start"
                style="padding-left: 10px"
              >
                <div class="d-flex">
                  <span
                    class="C000052_vipLevelName__H8TRa ms-0"
                    style="color: #e5e5e7"
                    >{{ $t("home_text9") }}</span
                  >
                  <div
                    class="mx-2 C000052_vipLevelArea__F-CJG align-items-center d-flex fw-bolder justify-content-center text-white"
                  >
                    {{ $t("home_text3") }} {{ vip }}
                  </div>
                </div>
                <span class="">{{ $t("home_text10") }}</span>
              </div>
            </div>
          </div>
          <div style="margin-right: 20px" class="w-100 mt-2">
            <div class="C000052_vip__i7B-6">
              <div class="C000052_vipProgress__YZG-Y">
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="C000052_vipProgressTitle__sbjvK">{{
                      $t("home_text12").toUpperCase()
                    }}</span>
                  </div>
                  <div>
                    <span class="C000052_vipProgressNum__WcG1p"
                      >{{ $t("cur") }} {{ deposit }}/{{ $t("cur")
                      }}<span style="">{{ next_deposit }}</span></span
                    >
                  </div>
                </div>
                <div class="C000052_vipProgressBar__oX2O5">
                  <div
                    class="ant-progress ant-progress-line ant-progress-status-active ant-progress-show-info ant-progress-small d-flex"
                  >
                    <div class="ant-progress-outer">
                      <div class="ant-progress-inner">
                        <div
                          class="ant-progress-bg"
                          :style="{
                            width: percent_deposit + '%',
                          }"
                          style="height: 12px; background: rgb(179 225 49)"
                        ></div>
                      </div>
                    </div>
                    <span class="ant-progress-text" title="0%"
                      >[{{ percent_deposit }}%]</span
                    >
                  </div>
                </div>
                <!-- <div class="d-flex justify-content-between mt-3">
                  <div>
                    <span class="C000052_vipProgressTitle__sbjvK">{{
                      $t("home_text13").toUpperCase()
                    }}</span>
                  </div>
                  <div>
                    <span class="C000052_vipProgressNum__WcG1p"
                      >{{ $t("cur") }} {{ bet }}/{{ $t("cur")
                      }}<span style="">{{ next_bet }}</span></span
                    >
                  </div>
                </div>
                <div class="C000052_vipProgressBar__oX2O5">
                  <div
                    class="ant-progress ant-progress-line ant-progress-status-active ant-progress-show-info ant-progress-small d-flex"
                  >
                    <div class="ant-progress-outer">
                      <div class="ant-progress-inner">
                        <div
                          class="ant-progress-bg"
                          :style="{
                            width: percent_bet + '%',
                          }"
                          style="height: 12px; background: rgb(179 225 49)"
                        ></div>
                      </div>
                    </div>
                    <span class="ant-progress-text" title="0%"
                      >[{{ percent_bet }}%]</span
                    >
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex justify-content-between flex-column flex-xl-row member-1200_gap"
          style="margin-top: 14px"
        >
          <div class="C000052_list__VxC5i" style="">
            <div class="C000052_listItem__vfMvU" @click="m_type = 'activity'">
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/member_ic1_overview.png" />
              </div>
              <span
                class="C000052_listItemTitle__Lr6Z0"
                :class="m_type == 'activity' ? 'text-new-gold' : ''"
                >{{ $t("myactivity") }}</span
              >
            </div>
            <div class="C000052_listItem__vfMvU" @click="m_type = 'myacc'">
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/member_ic2_account.png" />
              </div>
              <span
                class="C000052_listItemTitle__Lr6Z0"
                :class="m_type == 'myacc' ? 'text-new-gold' : ''"
                >{{ $t("myacc") }}</span
              >
            </div>
            <div class="C000052_listItem__vfMvU" @click="m_type = 'history'">
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/member_ic3_record.png" />
              </div>
              <span
                class="C000052_listItemTitle__Lr6Z0"
                :class="m_type == 'history' ? 'text-new-gold' : ''"
                >{{ $t("left_menu_text5") }}</span
              >
            </div>
            <div class="C000052_listItem__vfMvU" @click="m_type = 'message'">
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/member_ic4_news.png" />
              </div>
              <span
                class="C000052_listItemTitle__Lr6Z0"
                :class="m_type == 'message' ? 'text-new-gold' : ''"
                >{{ $t("left_menu_text6_1") }}</span
              >
            </div>
            <div class="C000052_listItem__vfMvU" @click="m_type = 'rent'">
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/member_ic5_asset.png" />
              </div>
              <span
                class="C000052_listItemTitle__Lr6Z0"
                :class="m_type == 'rent' ? 'text-new-gold' : ''"
                >{{ $t("rent_text1") }}</span
              >
            </div>
            <!-- <div class="C000052_listItem__vfMvU" @click="m_type = 'invest'">
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/member_ic5_asset.png" />
              </div>
              <span
                class="C000052_listItemTitle__Lr6Z0"
                :class="m_type == 'invest' ? 'text-new-gold' : ''"
                >{{ $t("text_assets_center") }}</span
              >
            </div> -->
            <!-- <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/BO/invite')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_3_share.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("left_menu_text4")
              }}</span>
            </div>
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/ME/wallet')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_4_recharge.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("text_recharge_1")
              }}</span>
            </div> -->
          </div>
          <div style="width: 100%">
            <ActivityView v-if="m_type == 'activity'" />
            <Myacc v-if="m_type == 'myacc'" />
            <HistoryView v-if="m_type == 'history'" />
            <MessageView v-if="m_type == 'message'" />
            <rentView v-if="m_type == 'rent'" />
            <!-- <InvestView v-if="m_type == 'invest'" /> -->
          </div>
        </div>
      </div>
    </div>
    <bighead
      @flag_val="flag_big_fun"
      v-if="flag_head && flag_code_uid"
    ></bighead>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";
import "@/assets/style/member.css";
import bighead from "./Home/big_head_img.vue";
import ActivityView from "./Activity.vue";
import Myacc from "./Myacc.vue";
import HistoryView from "./History.vue";
import MessageView from "./Message.vue";
import rentView from "./rent_income.vue";
// import InvestView from "./myinvest.vue";

export default {
  name: "member",
  setup() {},
  data() {
    return {
      code: Cookies.get("code"),
      userid: localStorage.getItem("uid"),
      shareurl: "",
      flag_head: false,
      vip: 0,
      deposit: 0,
      bet: 0,
      next_vip: 0,
      next_deposit: 0,
      next_bet: 0,
      big_head_img_set: localStorage.getItem("big_logo"),
      m_type: "activity",
    };
  },
  computed: {
    percent_deposit() {
      return this.deposit == 0
        ? 0
        : ((this.deposit * 100) / this.next_deposit).toFixed();
    },
    percent_bet() {
      return this.bet == 0 ? 0 : ((this.bet * 100) / this.next_bet).toFixed();
    },

    flag_code_uid() {
      return Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
        ? false
        : true;
    },
  },
  components: {
    bighead,
    ActivityView,
    Myacc,
    HistoryView,
    MessageView,
    rentView,
    // InvestView,
  },
  watch: {},
  created() {
    if (this.flag_code_uid) {
      this.info();
      this.share();
    }

    if (this.$route.params.type != null) {
      this.m_type = this.$route.params.type;
      this.$router.push("/VIP");
    }
  },
  mounted() {},
  methods: {
    ...mapActions(["logout_public"]),
    logout: function () {
      this.logout_public();
      this.$router.push("/");
      window.location.reload();
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.shareurl = res.url;
        }
      });
    },
    info: function () {
      let param = {
        action: "utype",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.vip = res.vip;
          this.deposit = res.deposit;
          this.bet = res.bet;
          this.next_vip = res.next_vip;
          this.next_deposit = res.next_deposit;
          this.next_bet = res.next_bet;
        }
      });
    },
    Copy: function () {
      var content = this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    flag_big_fun: function (e, imgval) {
      this.flag_head = e;
      localStorage.setItem("big_logo", imgval);
      this.big_head_img_set = imgval;
    },
  },
};
</script>
