<template>
  <div>
    <div class="mb-5">
      <div>
        <div class="py-32 md-py-5 container">
          <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
            {{ $t("text_vip_new_2") }}
          </p>
          <p
            class="md-fs-f-44 fs-f-2-5rem fw-bold text-center mt-5 mx-auto"
            style="max-width: 720px"
          >
            {{ $t("text_vip_new_3") }}<br />
            {{ $t("text_vip_new_4") }}<br />
            {{ $t("text_vip_new_5") }}
          </p>
        </div>
      </div>
      <div class="my-bg-grey">
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_6") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_7") }}
              </p>
              <p
                class="lg-fs-f-normal fs-f-1-125rem"
                v-html="$t('text_vip_new_8')"
              ></p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/1.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row flex-row-reverse">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_9") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_10") }}
              </p>
              <p class="lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_11") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/2.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="my-bg-grey py-32 md-py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_vip_new_12") }}
            </p>
            <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
              {{ $t("text_vip_new_13") }}
            </p>
            <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_vip_new_14") }}
            </p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/img_vip/3.png" class="w-100" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row flex-row-reverse">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_vip_new_15") }}
            </p>
            <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
              {{ $t("text_vip_new_16") }}
            </p>
            <p
              class="lg-fs-f-normal fs-f-1-125rem"
              v-html="$t('text_vip_new_17')"
            ></p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/img_vip/4.png" class="w-100" />
          </div>
        </div>
      </div>
    </div> -->
      <div class="my-bg-grey">
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_12") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_13") }}
              </p>
              <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_14") }}
              </p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/3.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="py-32 md-py-5 container">
          <div class="row flex-row-reverse">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_15") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_16") }}
              </p>
              <p
                class="lg-fs-f-normal fs-f-1-125rem"
                v-html="$t('text_vip_new_17')"
              ></p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/4.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_18") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_19") }}
              </p>
              <p
                class="lg-fs-f-normal fs-f-1-125rem"
                v-html="$t('text_vip_new_20')"
              ></p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/5.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row flex-row-reverse">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_21") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_22") }}
              </p>
              <p
                class="lg-fs-f-normal fs-f-1-125rem"
                v-html="$t('text_vip_new_23')"
              ></p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/6.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_24") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_25") }}
              </p>
              <p
                class="lg-fs-f-normal fs-f-1-125rem"
                v-html="$t('text_vip_new_26')"
              ></p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/7.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="py-32 md-py-5 container">
          <div class="row flex-row-reverse">
            <div class="col-lg-6 col-12">
              <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
                {{ $t("text_vip_new_27") }}
              </p>
              <p class="md-fs-f-44 fs-f-2-5rem fw-bold">
                {{ $t("text_vip_new_28") }}
              </p>
              <p
                class="lg-fs-f-normal fs-f-1-125rem"
                v-html="$t('text_vip_new_29')"
              ></p>
            </div>

            <div class="col-lg-6 col-12 mt-5">
              <img src="@/assets/img/img_vip/8.png" class="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div class="px-lg-3">
        <qaview class="mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/style/new_vip.scss";
import qaview from "./vip_common_qa.vue";
export default {
  name: "vip",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: { qaview },
  computed: {},
  methods: {},
  watch: {},
};
</script>
