<template>
  <div class="">
    <div class="content_top position-relative">
      <img
        src="@/assets/img/img_intro_company/home-pc.png"
        class="w-100 c-pointer intro_top_img"
        @click="open_login('isreg')"
      />
      <img
        src="@/assets/img/img_intro_company/home-mb.png"
        class="w-100 c-pointer intro_top-768_img"
        @click="open_login('isreg')"
      />
      <!-- <div class="header-wrapper">
        <div class="header-wrapper-left">
          <div class="d-grid hwl-d">
            <h1 style="color: #c9bd96">
              <span style="font-size: 1.8rem">{{ $t("text_main1") }}</span>
              {{ $t("text_main1__1") }}
            </h1>
            <h1 style="color: #c9bd96">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                $t("text_main1_1")
              }}
              <span style="font-size: 1.8rem">{{ $t("text_main1_1_2") }}</span>
            </h1>
            <div class="d-grid justify-content-center align-items-center">
              <div class="d-flex justify-content-center">
                <button class="reg_instantly_btn" @click="open_login('isreg')">
                  {{ $t("text_main2") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="content_casino_sport d-grid mt-4">
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_1.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text1") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p1.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text2") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text3") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_2.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text5") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p2.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text6") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text7") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_3.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text9") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p3.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text10") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text11") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_4.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text13") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p4.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text14") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text15") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a class="d-flex flex-column" style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_5.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text17") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p5.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text18") }}</p>
          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text19") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">
          {{ $t("intro_company_text16") }}</a
        >
      </a>
      <a class="d-flex flex-column" style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_6.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text21") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p6.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text22") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text23") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a class="d-flex flex-column" style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_7.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text25") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p7.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text26") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text27") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a class="d-flex flex-column" style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_8.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text29") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/img_intro_company/p8.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text30") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("intro_company_text31") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
      <a class="d-flex flex-column last_fix" style="gap: 1rem"
        @click="open_login('isreg')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img
            src="@/assets/img/img_intro_company/ic_9.png"
            style="width: 23px"
          />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("intro_company_text33") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img intro_top_img"
              src="@/assets/img/img_intro_company/p9.png"
              height="238"
            />

            <img
              class="content_casino_sport_img intro_top-768_img"
              src="@/assets/img/img_intro_company/p9_mb.png"
              height="238"
            />
          </a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("intro_company_text34") }}</p>

          <p
            style="color: #b1bad3; font-size: 14px"
            v-html="$t('intro_company_text35')"
          ></p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{
          $t("intro_company_text16")
        }}</a>
      </a>
    </div>

    <div class="mt-4 bg-1a px-2 py-3">
      <p class="fw-bold">{{ $t("text_vip_honor") }}</p>
      <div class="d-flex align-items-center px-3 flex-768-column gap-768-0">
        <img
          src="@/assets/img/img_intro_company/p10-logo-c-1.png"
          class="h-100 w-768-100 w-769-50"
        />
        <img
          src="@/assets/img/img_intro_company/p10-logo-c-2.png"
          class="h-100 w-768-100 w-769-50"
        />
      </div>
    </div>

    <div class="mt-4 bg-1a px-2 py-3">
      <p class="fw-bold">{{ $t("text_vip_honor") }}</p>
      <div class="d-flex align-items-center px-3 flex-768-column gap-768-0">
        <img
          src="@/assets/img/img_intro_company/p11-logo-c-1.png"
          class="h-100 w-768-100 w-769-50"
        />
        <img
          src="@/assets/img/img_intro_company/p11-logo-c-2.png"
          class="h-100 w-768-100 w-769-50"
        />
      </div>
    </div>

    <div class="mt-4 bg-1a position-relative">
      <img
        src="@/assets/img/img_intro_company/p12.png"
        class="w-100 position-relative intro_top_img"
      />

      <div class="intro_top-768_img">
        <img
          src="@/assets/img/img_intro_company/p12_mb.png"
          class="w-100 c-pointer"
        />
        <div style="height: 60px; background: #161616"></div>
      </div>
      <button
        class="intro_instantly_btn intro_instantly_left_btn"
        @click="open_login('isreg')"
      >
        {{ $t("text_main2") }}
      </button>
    </div>

    <div class="mt-4 bg-1a position-relative">
      <img
        src="@/assets/img/img_intro_company/p13.png"
        class="w-100 position-relative intro_top_img"
      />
      <div class="intro_top-768_img">
        <img
          src="@/assets/img/img_intro_company/p13_mb.png"
          class="w-100 c-pointer"
        />
        <div style="height: 60px; background: #161616"></div>
      </div>
      <button
        class="intro_instantly_btn intro_instantly_right_btn"
        @click="open_login('isreg')"
      >
        {{ $t("text_main2") }}
      </button>
    </div>

    <dwrank class="mt-4" />
  </div>
</template>
<script>
import dwrank from "./dw_rank_vip.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getService"]),
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
    link: function () {
      var url = this.$store.state.service.qq.qq;
      window.open(url);
    },
  },
  mounted() {},
  created() {
    this.getService();
  },
  watch: {},
  computed: {},
  components: {
    dwrank,
  },
};
</script>
